import CheroletOrlando2013 from '../src/images2/Chevrolet Orlando 2013.jpg';
import ChevroletTrax2024 from '../src/images2/Chevrolet trax 2024.jpg';
import ChevroletTrax2025 from '../src/images2/chevrolet trax.jpg';
import ChryslerPacifica2022 from '../src/images2/chevrolet.jpg';
import FIAT5002013 from '../src/images2/FIA.jpg';
import FordF1502016 from '../src/images2/Ford.jpg';
import FordMustang2017 from '../src/images2/Mustang.jpg';
import HyundaiSantaFe2023 from '../src/images2/Hundai Santa Fe.jpg';
import MazdaMazda62018 from '../src/images2/Mazda 6 2018.jpg';
import MercedesBenzCLAClass2020 from '../src/images2/Mercedes-Benz CLA.jpg';
import MercedesBenzGLCClass2017 from '../src/images2/Mercedes-Benz GLC.jpg';
import MercedesBenzMetris2018 from '../src/images2/Mercedes-Benz Metris 2018.jpg';
import PorsheMacan2016 from '../src/images2/Porshe Macan 2016.jpg';
import VolkswagenTiguan2022 from '../src/images2/Volkswagen Tiguan.jpg';
import ChryslerTownCountry from '../src/images2/ChrysleyTownAndCountry.jpg'
import CheroletOrlando from '../src/images2/ChevroletOrlando.jpg'
import SmartForTwo from '../src/images2/SmartFortwo.jpg'
import MercedesBenzMetris from '../src/images2/MercedesBenzMetris.jpg'
import FordFlex from '../src/images2/FordFlex.jpeg'
import FordMustang2024 from '../src/images2/FordMustang2024.jpg'
import MercedesBenzMetris2016 from '../src/images2/MercedesBenzMetris2016.jpg'
import bmwx3 from '../src/images2/X3.jpg'

export const carListings = [
  {
    "name": "Chevrolet Trax",
    "year": 2025,
    "transmission": "Automatic Transmission",
    "imageUrl": ChevroletTrax2025,
    "link": "https://turo.com/ca/en/suv-rental/canada/richmond-bc/chevrolet/trax/2834836?endDate=08%2F28%2F2024&endTime=10%3A00&startDate=08%2F25%2F2024&startTime=10%3A00"
  },
  {
    "name": "Chrysler Pacifica",
    "year": 2022,
    "transmission": "Automatic Transmission",
    "imageUrl": ChryslerPacifica2022,
    "link": "https://turo.com/ca/en/minivan-rental/canada/richmond-bc/chrysler/pacifica/2738965?endDate=08%2F28%2F2024&endTime=10%3A00&startDate=08%2F25%2F2024&startTime=10%3A00"
  },
  {
    "name": "FIAT 500",
    "year": 2013,
    "transmission": "Automatic Transmission",
    "imageUrl": FIAT5002013,
    "link": "https://turo.com/ca/en/car-rental/canada/richmond-bc/fiat/500/2459710?endDate=08%2F28%2F2024&endTime=10%3A00&startDate=08%2F25%2F2024&startTime=10%3A00"
  },
  {
    "name": "Ford F-150",
    "year": 2016,
    "transmission": "Automatic Transmission",
    "imageUrl": FordF1502016,
    "link": "https://turo.com/ca/en/truck-rental/canada/richmond-bc/ford/f-150/1929406?endDate=08%2F28%2F2024&endTime=10%3A00&startDate=08%2F25%2F2024&startTime=10%3A00"
  },
  {
    "name": "Chevrolet Orlando",
    "year": 2013,
    "transmission": "Automatic Transmission",
    "imageUrl": CheroletOrlando2013,
    "link": "https://turo.com/ca/en/suv-rental/canada/richmond-bc/chevrolet/orlando/2853863?endDate=09%2F11%2F2024&endTime=10%3A00&startDate=09%2F08%2F2024&startTime=10%3A00"
  },
  {
    "name": "Chevrolet Trax",
    "year": 2024,
    "transmission": "Automatic Transmission",
    "imageUrl": ChevroletTrax2024,
    "link": "https://turo.com/ca/en/suv-rental/canada/richmond-bc/chevrolet/trax/2844213?endDate=09%2F11%2F2024&endTime=10%3A00&startDate=09%2F08%2F2024&startTime=10%3A00"
  },
  {
    "name": "Mazda 6",
    "year": 2018,
    "transmission": "Automatic Transmission",
    "imageUrl": MazdaMazda62018,
    "link": "https://turo.com/ca/en/car-rental/canada/richmond-bc/mazda/mazda6/2839354?endDate=09%2F11%2F2024&endTime=10%3A00&startDate=09%2F08%2F2024&startTime=10%3A00"
  },
  {
    "name": "Ford Mustang",
    "year": 2017,
    "transmission": "Automatic Transmission",
    "imageUrl": FordMustang2017,
    "link": "https://turo.com/ca/en/car-rental/canada/richmond-bc/ford/mustang/2092542?endDate=08%2F28%2F2024&endTime=10%3A00&startDate=08%2F25%2F2024&startTime=10%3A00"
  },
  {
    "name": "Hyundai Santa Fe",
    "year": 2023,
    "transmission": "Automatic Transmission",
    "imageUrl": HyundaiSantaFe2023,
    "link": "https://turo.com/ca/en/suv-rental/canada/richmond-bc/hyundai/santa-fe/2521991?endDate=08%2F28%2F2024&endTime=10%3A00&startDate=08%2F25%2F2024&startTime=10%3A00"
  },
  {
    "name": "Mercedes-Benz CLA-Class",
    "year": 2020,
    "transmission": "Automatic Transmission",
    "imageUrl": MercedesBenzCLAClass2020,
    "link": "https://turo.com/ca/en/car-rental/canada/richmond-bc/mercedes-benz/cla-class/2624181?endDate=08%2F28%2F2024&endTime=10%3A00&startDate=08%2F25%2F2024&startTime=10%3A00"
  },
  {
    "name": "Mercedes-Benz GLC-Class",
    "year": 2017,
    "transmission": "Automatic Transmission",
    "imageUrl": MercedesBenzGLCClass2017,
    "link": "https://turo.com/ca/en/suv-rental/canada/richmond-bc/mercedes-benz/glc-class/2735047?endDate=08%2F28%2F2024&endTime=10%3A00&startDate=08%2F25%2F2024&startTime=10%3A00"
  },
  {
    "name": "Mercedes-Benz Metris golden",
    "year": 2018,
    "transmission": "Automatic Transmission",
    "imageUrl": MercedesBenzMetris2018,
    "link": "https://turo.com/ca/en/minivan-rental/canada/richmond-bc/mercedes-benz/metris/2621117?endDate=08%2F28%2F2024&endTime=10%3A00&startDate=08%2F25%2F2024&startTime=10%3A00"
  },
  {
    "name": "Volkswagen Tiguan",
    "year": 2022,
    "transmission": "Automatic Transmission",
    "imageUrl": VolkswagenTiguan2022,
    "link": "https://turo.com/ca/en/suv-rental/canada/richmond-bc/volkswagen/tiguan/2683572?endDate=08%2F28%2F2024&endTime=10%3A00&startDate=08%2F25%2F2024&startTime=10%3A00"
  },
  {
    "name": "Porsche Macan",
    "year": 2016,
    "transmission": "Automatic Transmission",
    "imageUrl": PorsheMacan2016,
    "link": "https://turo.com/ca/en/suv-rental/canada/richmond-bc/porsche/macan/2173178?endDate=08%2F28%2F2024&endTime=10%3A00&startDate=08%2F25%2F2024&startTime=10%3A00"
  },
  {
    "name": "Chrysler Town & Country",
    "year": 2015,
    "transmission": "Automatic Transmission",
    "imageUrl": ChryslerTownCountry,
    "link": "https://turo.com/ca/en/minivan-rental/canada/richmond-bc/chrysler/town-country/2878160"
  },
  {
    "name": "Cherolet Orlando",
    "year": 2012,
    "transmission": "Automatic Transmission",
    "imageUrl": CheroletOrlando,
    "link": "https://turo.com/ca/en/suv-rental/canada/richmond-bc/chevrolet/orlando/2866580"
  },
  {
    "name": "Smart For two",
    "year": 2016,
    "transmission": "Automatic Transmission",
    "imageUrl": SmartForTwo,
    "link": ""
  },
  {
    "name": "Mercedes Benz Metris",
    "year": 2018,
    "transmission": "Automatic Transmission",
    "imageUrl": MercedesBenzMetris,
    "link": "https://turo.com/ca/en/car-rental/canada/richmond-bc/mercedes-benz/metris/2635525"
  },
  {
    "name": "Ford Flex",
    "year": 2012,
    "transmission": "Automatic Transmission",
    "imageUrl": FordFlex,
    "link": "https://turo.com/ca/en/suv-rental/canada/richmond-bc/ford/flex/2910174"
  },
  {
    "name": "Ford Mustang",
    "year": 2024,
    "transmission": "Automatic Transmission",
    "imageUrl": FordMustang2024,
    "link": "https://turo.com/ca/en/car-rental/canada/richmond-bc/ford/mustang/2918515"
  },
  {
    "name": "Mercedes Benz Metris",
    "year": 2016,
    "transmission": "Automatic Transmission",
    "imageUrl": MercedesBenzMetris2016,
    "link": "https://turo.com/us/en/minivan-rental/canada/richmond-bc/mercedes-benz/metris/2900725"
  },
  {
    "name": "BMW X3",
    "year": 2021,
    "transmission": "Automatic Transmission",
    "imageUrl": bmwx3,
    "link": "https://turo.com/ca/en/your-car/3054854"
  },
]
